import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import SEO from 'components/seo'
import { Breadcrumb } from 'gatsby-plugin-breadcrumb'
import parse from 'html-react-parser'
import { composeClasses, jsonId } from 'utils'
import linkIcon from '../images/link-icon.svg'
import SocialLinks from 'components/socialLinks'
import HelmetExport from 'react-helmet'
import {
  initiativeWrapper,
  verticalContainer,
  breadCrumbsSection,
  verticalContainerInner,
  initiativesContainer,
  cardWrapper,
  cardContainer,
  initiativeImg,
  buttonWrapper,
  shareContainer
} from '../styles/initiatives.module.css'

export const query = graphql`
  query {
    wordPress {
      pages(where: { name: "Our Initiatives" }) {
        edges {
          node {
            initiativesFieldGroup {
              initiativesHeroHeading
              initiativesService {
                initiativeTitle
                initiativeDescription
                initiativeButtonText
                initiativeButtonLink
                initiativeImage {
                  sourceUrl
                }
              }
            }
            slug
            title
          }
        }
      }
      footers {
        edges {
          node {
            footersFieldGroup {
              socialLinks {
                socialTitle
                socialLogo
                socialUrl
              }
            }
          }
        }
      }
    }
    site {
      siteMetadata {
        siteUrl
      }
    }
  }
`

const Initiatives = ({ data, pageContext }) => {
  const initiativeData = data.wordPress.pages.edges[0].node
  const ourInitiatives = initiativeData.initiativesFieldGroup.initiativesService
  const footerData = data.wordPress.footers.edges[0].node.footersFieldGroup
  const initiativeHeading =
    initiativeData.initiativesFieldGroup.initiativesHeroHeading
  const pageTitle = data.wordPress.pages.edges[0].node.title
  const siteUrl = data.site.siteMetadata.siteUrl
  const contextSlug = pageContext.slug
  const {
    breadcrumb: { crumbs }
  } = pageContext

  return (
    <div className={initiativeWrapper}>
      <HelmetExport>
        {jsonId({
          '@context': 'https://schema.org',
          '@type': 'BreadcrumbList',
          'itemListElement': [{
            '@type': 'ListItem',
            'item': siteUrl,
            'name': 'Home',
            'position': 1
          }, {
            '@type': 'ListItem',
            'item': `${siteUrl}/${contextSlug}`,
            'name': `${pageTitle}`,
            'position': 2
          }]
        })}
      </HelmetExport>
      <SEO
        description='This page explains Deimos’ Initiatives and how they are used to help our members and their careers.'
        title='Our Initiatives'
      />
      <div
        className={composeClasses(
          verticalContainer,
          breadCrumbsSection
        )}
      >
        <div className={verticalContainerInner}>
          <Breadcrumb
            crumbLabel='Our Initiatives'
            crumbs={crumbs}
            crumbSeparator=' > '
          />
        </div>
      </div>
      {ourInitiatives.length > 0 && (
        <div className={initiativesContainer}>
          <h1>{initiativeHeading}</h1>
          {ourInitiatives.map((initiative, key) => {
            return (
              <div
                className={cardWrapper}
                key={key.toString()}>
                <div className={cardContainer}>
                  <div>
                    <h2>{initiative.initiativeTitle}</h2>
                    {parse(initiative.initiativeDescription)}
                  </div>
                  <img
                    alt='initiativeImage'
                    className={initiativeImg}
                    height={160}
                    src={initiative.initiativeImage.sourceUrl}
                    width={336}
                  />
                </div>
                <div className={buttonWrapper}>
                  <a
                    className={shareContainer}
                    href={initiative.initiativeButtonLink}
                    target='_blank'
                  >
                    <img
                      alt='share icon'
                      src={linkIcon} />
                    <p>{initiative.initiativeButtonText}</p>
                  </a>
                </div>
              </div>
            )
          })}
        </div>
      )}
      <SocialLinks
        hasWhiteBackground
        socialLinks={footerData.socialLinks} />
    </div>
  )
}

Initiatives.propTypes = {
  data: PropTypes.object.isRequired,
  pageContext: PropTypes.object.isRequired
}

export default Initiatives

// extracted by mini-css-extract-plugin
export var initiativeWrapper = "initiatives-module--initiativeWrapper--zb5Xf";
export var verticalContainer = "initiatives-module--verticalContainer--qMS+W";
export var verticalContainerInner = "initiatives-module--verticalContainerInner--oUj-M";
export var cardWrapper = "initiatives-module--cardWrapper--C-7vu";
export var cardContainer = "initiatives-module--cardContainer--KGr+Z";
export var initiativeImg = "initiatives-module--initiativeImg--EA542";
export var initiativesContainer = "initiatives-module--initiativesContainer--aNQQe";
export var buttonWrapper = "initiatives-module--buttonWrapper--10R1g";
export var shareContainer = "initiatives-module--shareContainer--rbfd+";
export var socialLinksSection = "initiatives-module--socialLinksSection--Q-aKv";
export var verticalBlogInner = "initiatives-module--verticalBlogInner--tt0ru";
export var verticalInner = "initiatives-module--verticalInner--VFsgb";
export var breadCrumbsSection = "initiatives-module--breadCrumbsSection--y4G7n";